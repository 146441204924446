import React from 'react';
import {withRouter} from 'react-router';
import ReactGA from 'react-ga';

const withAnalytics = (Component) => {
  class WithAnalytics extends React.Component {
    componentDidMount() {
      this.trackPage(this.props.location.pathname)
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        this.trackPage(nextPage);
      }
    }

    trackPage = (page) => {
      ReactGA.set({...page});
      ReactGA.pageview(page);
    }

    render() {
      return <Component {...this.props} />;
    }
  };

  return withRouter(WithAnalytics);
};

export default withAnalytics;
